import React, { useState, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";
import "./styles.css";

const QRCodeGenerator = () => {
  const [text, setText] = useState("");
  const [qrCode, setQRCode] = useState("");
  const [fadeIn, setFadeIn] = useState(false);
  const qrRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setQRCode(text);
    setFadeIn(true);
    setTimeout(() => setFadeIn(false), 500);
  };

  const handleDownload = async () => {
    if (qrRef.current) {
      const canvas = await html2canvas(qrRef.current, {
        backgroundColor: "#ffffff",
      });
      canvas.toBlob((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // generate host name
        let hostName;
        try {
          const url = new URL(text);
          hostName = url.hostname;
        } catch (e) {
          hostName = text;
        }

        link.download = `${hostName || "qr_code"}.jpg`;
        link.click();
        URL.revokeObjectURL(link.href);
      }, "image/jpeg");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-pastel-sky p-4">
      <header className="absolute top-3 w-full py-10">
        <h1 className="text-center text-pastel-white text-4xl font-bold flex items-center justify-center">
          <img src="/logo192.png" alt="Logo" className="w-10 h-10 mr-3 inline-block" />
          TEXT 2 QR
        </h1>
      </header>
      <div className="w-full max-w-lg mt-20">
        <form
          onSubmit={handleSubmit}
          className="bg-pastel-yellow shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 md:px-12 md:pt-8 md:pb-10"
        >
          <div className="mb-8">
            <label
              className="block text-gray-700 text-xl font-bold mb-4 md:text-2xl"
              htmlFor="text"
            >
              INPUT
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg md:text-xl"
              id="text"
              type="text"
              placeholder="Type any text.."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-pastel-blue hover:bg-pastel-cloud text-pastel-ivory font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-xl"
              type="submit"
            >
              GENERATE
            </button>
          </div>
        </form>
        {qrCode && (
          <div className={`mt-6 flex flex-col items-center ${fadeIn ? "fade-in" : ""}`}>
            <div ref={qrRef} className="p-4 bg-white rounded-lg md:p-6">
              <QRCodeSVG value={qrCode} size={256} className="w-full h-full md:w-auto md:h-auto" />
            </div>
            <button
              onClick={handleDownload}
              className="mt-4 bg-pastel-green hover:bg-pastel-grass text-pastel-ivory font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-xl"
            >
              DOWNLOAD
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
